import api from "../utils/api";

export const addQuestion = async (question, retrieval) => {
  const endpoint = `cognitive/assistants/${retrieval}/chats/`;
  const resp = await api.post(endpoint, { message: question });
  return resp;
};

export const addQuestionRetData = async (question, retrieval, nodeId) => {
  const endpoint = `cognitive/assistants/${retrieval}/chats/`;
  const resp = await api.post(endpoint, { message: question });
  return resp;
};

export const getChat = async (id) => {
  const { data } = await api.get(`cognitive/chats/${id}/`);
  return data;
};

export const getChats = async (retrieval) => {
  const { data } = await api.get(`cognitive/assistants/${retrieval}/chats/`);
  return data;
};

export const addFeedback = async (chat, like, comment) => {
  const endpoint = `cognitive/chats/${chat}/`;
  if (comment === "") {
    const { data } = await api.put(endpoint, { like: like });
    return data;
  }
  const { data } = await api.put(endpoint, { like: like, comment: comment });
  return data;
};

export const addFeedback2 = async (chat, comment) => {
  const endpoint = `cognitive/chats/${chat}/`;
  const { data } = await api.put(endpoint, { comment: comment });
  return data;
};

export const matrixMode = async (chat, matrixMode) => {
  const endpoint = `cognitive/chats/${chat}/`;
  const resp = await api.put(endpoint, { matrix_mode: matrixMode });
  return resp;
};

const QuestionsApi = {
  addQuestion,
  getChat,
  getChats,
  addFeedback,
  addFeedback2,
  matrixMode,
};

export default QuestionsApi;
