import ShowQuestion from "./components/ShowQuestion";
import ShowQuestionApiCognitive from "./components/ShowQuestionApiCognitive";
import ShowQuestionApiCognitiveStreaming from "./components/ShowQuestionApiCognitiveStreaming";

function App(props) {
  return (
    <>
      {props.streaming === true && (
        <ShowQuestionApiCognitiveStreaming
          getRetrieval={props.getRetrieval}
          retrievalId={props.retrievalId}
          addQuestionRetData={props.addQuestionRetData}
          addMessage={props.addMessage}
          logo={props.logo}
          chat={props.chat}
          otherRefs={props.otherRefs}
          addFeedback={props.addFeedback}
          addFeedback2={props.addFeedback2}
          colors={props.colors}
          allow_files={props.allow_files}
          onChatIdUpdate={props.onChatIdUpdate}
        />
      )}
      {!props.streaming  && props.newApi === true && (
        <ShowQuestionApiCognitive
          getRetrieval={props.getRetrieval}
          retrievalId={props.retrievalId}
          addQuestionRetData={props.addQuestionRetData}
          addMessage={props.addMessage}
          logo={props.logo}
          chat={props.chat}
          otherRefs={props.otherRefs}
          addFeedback={props.addFeedback}
          addFeedback2={props.addFeedback2}
          matrix_mode={props.matrix_mode}
          addMatrixMode={props.addMatrixMode}
          colors={props.colors}
          allow_files={props.allow_files}
          onChatIdUpdate={props.onChatIdUpdate}
        />
      )}
      {!props.streaming  &&  props.newApi === false && (
        <ShowQuestion
          getRetrieval={props.getRetrieval}
          retrievalId={props.retrievalId}
          addQuestionRetData={props.addQuestionRetData}
          addMessage={props.addMessage}
          logo={props.logo}
          chat={props.chat}
          otherRefs={props.otherRefs}
          addFeedback={props.addFeedback}
          addFeedback2={props.addFeedback2}
          colors={props.colors}
          allow_files={props.allow_files}
          onChatIdUpdate={props.onChatIdUpdate}
        />
      )}
    </>
  );
}

export default App;
