import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Link,
  Chip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Message from "./Message";
import Feedback from "./Feedback";
import { Button } from "@mui/material";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import linkify from "./Utils";

// Initial values
const MAX_MSG_LENGTH = 1000;
const MAX_RESPONSES = 10;
const MAX_RESP_STATUS = 300;

const ShowQuestion = (props) => {
  const [isEnglish, setIsEnglish] = useState(false);
  const navigate = useNavigate();

  const [question, setQuestion] = useState(props.chat ? props.chat : null);
  const [messages, setMessages] = useState(
    props.chat ? props.chat.messages : []
  );

  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iniMessage, setIniMessage] = useState(null);
  const [maxMsgLength, setMaxMsgLength] = useState(MAX_MSG_LENGTH);
  const [maxResponses, setIMaxResponses] = useState(MAX_RESPONSES);
  const [ended, setEnded] = useState(false);
  const [topic, setTopic] = useState("");

  const [endedStatus, setEndedStatus] = useState(0);
  const [statusModal, setStatusModal] = useState(false);
  const [allowAsk, setAllowAsk] = useState(false);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    });
  };


  useEffect(() => {
    const browserLang = navigator.language || navigator.languages[0];
    if (browserLang.startsWith("en")) {
      setIsEnglish(true);
    }

    if (question == null) {
      const getInfoRetrieval = async (retrievalId) => {
        try {
          const data = await props.getRetrieval(retrievalId);
          setIniMessage(data.initial_message);
          setMaxMsgLength(data.max_msg_length);
          setIMaxResponses(data.max_responses);
        } catch (error) {
          console.log(error);
        }
      };
      getInfoRetrieval(props.retrievalId);
    } 
    if (props.chat) {
      setTopic(props.chat.topic);
    }
    setAllowAsk(true);
    scrollToBottom();
  }, [messages, props, question]);

  ////////// SET ENDED RESPONSE STATUS /////////////
  const requestStatus = (status) => {
    if (status > MAX_RESP_STATUS) {
      setEndedStatus(status);
    }
  };

  ////////// POPUP STATUS ERROR /////////////
  const showStatusModal = () => {
    setStatusModal(true);
    setEnded(true);
    setTimeout(() => {
      setStatusModal(false);
    }, 5000);
  };

  //////////// REFERENCES ////////////////
  const getUsedBlocks = (message) => {
    let usedBlocks = [];
    // The references can appear in the answer in the form of [1,2,3] or [1][2][3]
    let regex = /\[((\d+)(,( )*\d+)*)\]/g;
    let match = regex.exec(message.text);
    while (match != null) {
      let blocks = match[1].split(",");
      blocks.forEach((block) => usedBlocks.push(+block));
      match = regex.exec(message.text);
    }
    return usedBlocks;
  };

  const getUrlSource = (source) => {
    // Source is a text with this content: "https://www.youtube.com/watch?v=... (desde x hasta y)"
    // Returns only the url (until the first space)
    return source.split(" ")[0];
  };

  const getRestSource = (source) => {
    // Source is a text with this content: "https://www.youtube.com/watch?v=... (desde x hasta y)"
    // Returns only the rest of the text (after the first space)
    return source.split(" ").slice(1).join(" ");
  };

  /////////////// FUNCTION TO SEND MESSAGES //////////////
  const sendMessage = async () => {
    if (inputValue.trim() === "") return;
    setEndedStatus(0);

    const userMessage = {
      text: inputValue,
      is_user: true,
    };

    setMessages([...messages, userMessage]);
    setInputValue("");
    setIsLoading(true);

    const loadingMessage = {
      text: "Pensando...",
      is_user: false,
      loading: true,
    };
    if (isEnglish) {
      loadingMessage.text = "Thinking...";
    }

    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    try {
      // API call
      let response = null;
      if (question != null) {
        response = await props.addMessage(inputValue, question.id);
        if (response && response.data && props.onChatIdUpdate) {
          props.onChatIdUpdate(response.data.id);
        }
      } else {
        response = await props.addQuestionRetData(
          inputValue,
          props.retrievalId
        );
        if (response && response.data && props.onChatIdUpdate) {
          props.onChatIdUpdate(response.data.id);
        }
      }
      // see if errors
      if (response.status) {
        requestStatus(response.status);
        if (endedStatus > MAX_RESP_STATUS) {
          setEnded(true);
          showStatusModal();
        } else {
          setEnded(response.data.ended);
        }
      }
      // set Question chat
      setQuestion(response.data);

      // set list of messages
      if (iniMessage || iniMessage === "") {
        setMessages(response.data.messages.slice(1)); // Exclude initial message
      } else {
        setMessages(response.data.messages);
      }
    } catch (error) {
      console.error("Error:", error);
      showStatusModal();
    } finally {
      setIsLoading(false);
    }
  };

  //////////////// RETURN FUNCTION //////////////////////
  return (
    <div
      className="flex flex-col items-start w-full mb-10 space-y-8"
      style={{ minHeight: "50px" }}
    >
      {topic ? <Chip label={topic} variant="outlined" /> : null}
      <div
        className="w-full p-4 space-y-3 rounded shadow-inner bg-slate-200 shadow-slate-400"
        style={{ minHeight: "600px" }}
      >
        {iniMessage != null && (
          <Message
            isUser={false}
            text={linkify(iniMessage)}
            img_logo={props.logo}
            colors={props.colors}
          />
        )}
        {messages.map((message, index) =>
          message.is_user ? (
            <Message
              key={index}
              isUser={true}
              num_msgs={index + 1 - index / 2}
              max_msgs={maxResponses}
              colors={props.colors}
              text={
                <div
                  dangerouslySetInnerHTML={{
                    __html: linkify(message.text.replace(/\n/g, "<br />")),
                  }}
                />
              }
            />
          ) : index > 0 ? (
            <Message
              key={index}
              isUser={false}
              colors={props.colors}
              img_logo={props.logo}
              text={
                message.loading ? (
                  message.text
                ) : (
                  <div>
                    <Markdown className="items-end font-sans md:text-md">
                      {message.text.replace(/\\+/g, "\\")}
                    </Markdown>
                    {getUsedBlocks(message).length > 0 && (
                      <h4>{isEnglish ? "References:" : "Referencias:"}</h4>
                    )}
                    {messages[index].references.map((block, index2) => {
                      if (block.used) {
                        return (
                          <p key={index2}>
                            {"[" + block.reference_num + "] "}
                            {block.source.includes("https") ? (
                              <>
                                <a
                                  href={getUrlSource(block.source)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {getUrlSource(block.source)}
                                </a>{" "}
                                <i>{getRestSource(block.source)}</i>
                              </>
                            ) : (
                              block.source
                            )}
                          </p>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {props.otherRefs && (
                      <>
                        <h4>
                          {isEnglish
                            ? "Other related references:"
                            : "Otras referencias relacionadas:"}
                        </h4>
                        {messages[index].references.map((block, index2) => {
                          if (!block.used) {
                            return (
                              <p key={index2}>
                                {"[" + block.reference_num + "] "}
                                {block.source.includes("https") ? (
                                  <a
                                    href={block.source}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {block.source}
                                  </a>
                                ) : (
                                  block.source
                                )}
                              </p>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                  </div>
                )
              }
            />
          ) : (
            <Message
              isUser={false}
              text={linkify(iniMessage)}
              img_logo={props.logo}
              colors={props.colors}
            />
          )
        )}
      </div>
      <div ref={messagesEndRef} className="h-2" />
      <div className="sticky flex flex-row place-self-center bottom-2 bg-white shadow-md w-fit md:w-full p-2 border-solid border rounded-lg border-slate-200">
        <div className="grid grid-cols-12 mx-auto">
          <div className="col-span-12 md:col-span-2 col-start-1 md:col-start-1 my-1 md:ml-2 md:mr-4">
            <Button
              className="w-full"
              variant="contained"
              size="small"
              sx={{
                borderRadius: "10px",
                height: "10px",
                padding: "22px",
                alignItems: "center",
                alignSelf: "center",
                marginLeft: "0px",
                marginRight: "20px",
                textTransform: "none",
                backgroundColor: props.colors
                  ? props.colors.changeTopic.backgroundColor
                  : "#1463B2",
                color: props.colors
                  ? props.colors.changeTopic.textColor
                  : "#fff",
                ":hover": {
                  bgcolor: props.colors
                    ? props.colors.changeTopic.hoverColor
                    : "#2196f3",
                  color: "white",
                },
              }}
              onClick={() => {
                if (props.chat) {
                  navigate(`/projects/${props.chat.retrieval}/`);
                } else {
                  navigate(0);
                }
              }}
            >
              {isEnglish ? (
                <p className="font-sans text-xs">Change topic</p>
              ) : (
                <p className="font-sans text-xs">Cambiar tema</p>
              )}
              <AutoStoriesIcon sx={{ ml: 2 }} />
            </Button>
          </div>
          <div className="col-span-12 col-start-1 md:col-span-8 flex flex-row min-h-11">
            {maxResponses * 2 > messages.length && ended === false ? (
              <OutlinedInput
                className="w-full placeholder-shown"
                size="small"
                multiline
                error={inputValue.length > maxMsgLength}
                type="text"
                placeholder={isEnglish ? "Type here..." : "Escribe aquí..."}
                endAdornment={
                  <InputAdornment position="end">
                    <p
                      className={
                        "text-xs " +
                        (inputValue.length > maxMsgLength
                          ? "text-red-500"
                          : "text-slate-500")
                      }
                    >
                      {inputValue.length}/{maxMsgLength}
                    </p>
                  </InputAdornment>
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey) {
                    setInputValue(inputValue + "\n");
                  } else if (e.key === "Enter") {
                    sendMessage(inputValue);
                    setInputValue("");
                  }
                }}
                disabled={isLoading || allowAsk === false} // Add this line to disable the input field while thinking
              />
            ) : (
              <div className="flex items-center w-screen px-3 text-sm border-0 text-slate-500">
                {ended && endedStatus <= MAX_RESP_STATUS ? (
                  <p className="mr-1">
                    {isEnglish
                      ? "We should have ended this conversation."
                      : "Debimos finalizar esta conversación."}
                  </p>
                ) : endedStatus > MAX_RESP_STATUS ? (
                  <p className="mr-1">
                    {isEnglish
                      ? "System Error. Please try again."
                      : "Error del sistema. Por favor, inténtalo nuevamente."}
                  </p>
                ) : (
                  <p className="mr-1">
                    {isEnglish
                      ? "You have reached the maximum of 10 questions!"
                      : "¡Alcanzaste el máximo de 10 preguntas!"}
                  </p>
                )}
                <Link
                  component="button"
                  variant="inherit"
                  onClick={() => {
                    if (props.chat) {
                      navigate(`/projects/${props.chat.retrieval}/`);
                    } else {
                      navigate(0);
                    }
                  }}
                >
                  {isEnglish ? "Start a new chat" : "Inicia un nuevo chat"}
                </Link>
              </div>
            )}
            <IconButton
              className="bg-white"
              color={props.colors ? props.colors.arrowColor : "primary"}
              disabled={
                isLoading ||
                inputValue.length > maxMsgLength ||
                maxResponses * 2 === messages.length ||
                allowAsk === false
              }
              onClick={() => {
                sendMessage(inputValue);
                setInputValue("");
              }}
              text="Send"
            >
              <SendIcon />
            </IconButton>
          </div>
          <div className="col-span-12 md:col-span-2 my-1">
            <Feedback
              colors={props.colors}
              id={question ? question.id : -1}
              addFeedback={props.addFeedback}
              addFeedback2={props.addFeedback2}
            />
          </div>
        </div>
      </div>
      {statusModal && (
        //////////////////////////////////////////////////////
        ////////////////// POPUP ERROR ///////////////////////
        //////////////////////////////////////////////////////
        <div className="fixed z-100 inset-0">
          <div className="items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0 m-40">
            <div className="mt-20 inline-block align-bottom bg-white rounded text-left overflow-hidden shadow transform transition-all sm:align-middle px-8 pt-6">
              <div className="mb-6 w-full">
                {isEnglish ? (
                  <p className="text-lg mt-2 mb-2 text-gray-500">
                    There has been a system error, please try again.
                  </p>
                ) : (
                  <p className="text-lg mt-2 mb-2 text-gray-500">
                    Ha habido un error del sistema, por favor inténtelo
                    nuevamente.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        ////////////////// END POPUP ERRORS //////////////////
      )}
    </div>
  );
};

export default ShowQuestion;
