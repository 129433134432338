export const fetch_stream = (endpoint, body, onChunk, endFunc, onError, isJson=true) => {
  let headers = {
    // 'Authorization': `Token ${sessionStorage.getItem('token')}`,
  }
  if (isJson) {
    body = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }
  headers['X-Api-Key'] = process.env.REACT_APP_API_KEY

  fetch(process.env.REACT_APP_API_URL + endpoint, {
    method: 'POST',
    headers: headers,
    body: body,
  })
  .then((response) => {
    const reader = response.body.getReader();
    return new ReadableStream({
      start(controller) {
        function procesarDatos() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            let mensaje = new TextDecoder("utf-8").decode(value);
            onChunk(mensaje);
            controller.enqueue(value);
            procesarDatos();
          })
        }
        procesarDatos();
      }
    })
  })
  .then(stream => new Response(stream))
  .then(response => response.text())
  .then(() => {
    endFunc();
  })
  .catch((error) => {
    onError(error);
  });
}